import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import SiteLayout from "../components/SiteLayout"
import PageSEO from "../components/PageSEO"

const psalmList = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rhythm(1)};
`
const psalmListItem = css`
  min-width: 55px;
  padding: 8px;
  border: 2px solid #fff;
  background-color: #f8f8f8;
  text-align: center;
`

export default function MusicAndLyrics({ data }) {
  const { psalms, otherSongs } = groupAndSortPages(data)

  return (
    <SiteLayout>
      <PageSEO title="Music & Lyrics" />

      <h2>Music & Lyrics</h2>

      <h3>Individual Psalms</h3>
      <div css={psalmList}>
        {psalms.map(node => (
          <Link css={psalmListItem} key={node.id} to={node.fields.slug}>
            {node.fields.psalmNumber}
          </Link>
        ))}
      </div>

      <h3>Other Songs</h3>
      <ul>
        {otherSongs.map(node => (
          <li key={node.id}>
            <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
      </ul>

      <h3>Complete Collections</h3>
      <p>For your convenience, you may download complete file packages.</p>
      <ul>
        <li>
          Audio:{" "}
          <a href="/files/complete-collections/Genevan_Psalms_1–30.MP3.zip">
            Psalms 1-30
          </a>
          ,{" "}
          <a href="/files/complete-collections/Genevan_Psalms_31–60.MP3.zip">
            31-60
          </a>
          ,{" "}
          <a href="/files/complete-collections/Genevan_Psalms_61–90.MP3.zip">
            61-90
          </a>
          ,{" "}
          <a href="/files/complete-collections/Genevan_Psalms_91–120.MP3.zip">
            61-120
          </a>
          ,{" "}
          <a href="/files/complete-collections/Genevan_Psalms_121–150.MP3.zip">
            121-150
          </a>
          ,{" "}
          <a href="/files/complete-collections/Genevan_Psalms_extra.zip">
            Extras
          </a>
        </li>
        <li>
          Lyrics:
          <a href="/files/complete-collections/Gen_Psal_texts_COMPLETE.PDF">
            {" "}
            PDF
          </a>
          ,
          <a href="/files/complete-collections/Gen_Psal_texts_COMPLETE.HTM">
            {" "}
            HTML
          </a>
          ,
          <a href="/files/complete-collections/Gen_Psal_texts_COMPLETE.RTF">
            {" "}
            RFT
          </a>
        </li>
        <li>
          Sheet Music:
          <a href="/files/complete-collections/Genevan_Psalter_pages.pdf">
            {" "}
            Pages PDF
          </a>
          ,
          <a href="/files/complete-collections/Genevan_Psalter_spreads.pdf">
            {" "}
            Spreads PDF
          </a>
        </li>
      </ul>
    </SiteLayout>
  )
}

function groupAndSortPages(data) {
  const allNodes = data.allMarkdownRemark.edges
  const psalms = []
  const otherSongs = []

  allNodes.forEach(({ node }) => {
    if (node.frontmatter.title.startsWith("Psalm")) {
      node.fields.psalmNumber = Number(
        node.frontmatter.title.replace("Psalm ", "")
      )
      psalms.push(node)
    } else {
      otherSongs.push(node)
    }
  })

  const sortedPsalms = psalms.sort((psalmA, psalmB) => {
    if (psalmA.fields.psalmNumber > psalmB.fields.psalmNumber) {
      return 1
    } else if (psalmA.fields.psalmNumber < psalmB.fields.psalmNumber) {
      return -1
    } else {
      return 0
    }
  })

  return {
    psalms: sortedPsalms,
    otherSongs,
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/pages/(music-and-lyrics)/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            composed
            meter
            mode
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
